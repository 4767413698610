<template>
    <div class="mid-pla-crumbs-nav">
        <div class="nav">
            <span v-if="$route.meta.showHomeLink">
                <span class="item" @click="toHome">产业数据中台</span> 
                <em class="gap">/</em>
            </span>
            <span v-for="(item, index) in routeList" :key="item.path">
                <span class="item" :class="{active: index == routeList.length - 1}" @click="toLink(item)">{{item.meta.title}}</span> 
                <em class="gap" v-if="index < routeList.length - 1">/</em>
            </span>
            
            <!-- <span class="item active">应用注册</span> -->
            <!-- <em class="gap">/</em>
            <span class="item active">应用权限</span> -->
        </div>
        <h3 class="title">{{$route.meta.title}}</h3>
        <div class="extra-right-area">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        routeList() {
            // console.log('111')
            // console.log(this.$route.matched)
            return this.$route.matched.filter(item => item.meta.title && !item.meta.crumbsTitleHidden)
        }
    },
    methods: {
        toHome() {
            this.$router.push('/')
        },
        toLink(item) {
            this.$router.push(item.path)
        }
    }
}
</script>
