// with polyfills
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './core/lazy_use'
import './style/global.less'

import './permission'
import './utils/cookies'
import './utils/global'

import PageHeadWrapper from './layouts/components/PageHeadWrapper'

Vue.config.productionTip = false

Vue.component('page-head-wrapper', PageHeadWrapper)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
