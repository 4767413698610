import md5 from 'md5'
/*

*/
export function getSign (obj, secretKey) {
	if (Object.prototype.toString.call(obj) == "[object Object]") {
		let str = '';
		let sortArr = Object.keys(obj).sort()
		sortArr.forEach(key => {
			if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' ) {
                // console.log(key, obj[key])
				str += key + '=' + obj[key] + '&'
			}
		})
        str = str + 'secretKey=' + secretKey // az5CAEUb0qsEAZRThvmp8FD8tawR8jrx
        // console.log(str)
        // console.log(md5(str).toUpperCase())
        return md5(str).toUpperCase()
	}
	return '';
}

/*
    生成32位随机数的方法
*/
export function createNonce() {
    let arr = [
        'A','B','C','D','E','F','G','H','I','J','K','L','M','N',
        'O','P','Q','R','S','T','U','V','W','X','Y','Z', 
        '0', '1', '2', '3', '4','5','6','7','8', '9'
    ]
    let str = ''
    let L = arr.length - 1 
    for (let i = 0; i < 32; i ++) {
        str += arr[getRandomIntInclusive(0, L)]
    }
    return str
}

/* 得到一个两数之间的随机整数，包括两个数在内 */
export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值 
}