import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from "../layouts/BasicLayout.vue"

Vue.use(VueRouter)

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

const routes = [
  {
    path: '/',
    name: 'BasicLayout',
    component: BasicLayout,
    redirect: '/app-permissions',
    children: [
      {
        path: '/app-permissions',
        name: 'app-permissions',
        component: RouteView,
        redirect: '/app-permissions/app-registry',
        meta: { title: '应用权限', icon: 'app-permissions'},
        children: [
          {
            path: '/app-permissions/app-registry',
            name: 'app-registry',
            component: RouteView,
            redirect: '/app-permissions/app-registry/list',
            meta: { title: '应用注册' },
            children: [
              {
                path: '/app-permissions/app-registry/list',
                name: 'app-registry-list',
                component: () => import('@/views/app-permissions/app-registry/list'),
                meta: { title: '应用列表', crumbsTitleHidden: true },
              },
              {
                path: '/app-permissions/app-registry/detail',
                name: 'app-registry-detail',
                component: () => import('@/views/app-permissions/app-registry/detail'),
                meta: { title: '应用详情' },
              },
              {
                path: '/app-permissions/app-registry/key-management',
                name: 'app-registry-key-management',
                component: () => import('@/views/app-permissions/app-registry/key-management'),
                meta: { title: '密钥管理' },
              },
            ]
          },
          {
            path: '/app-permissions/data-permission',
            name: 'data-permission',
            component: () => import('@/components/Develop'),
            meta: { title: '数据权限' },
          }
        ]
      },
      {
        path: '/basic-data',
        name: 'basic-data',
        component: RouteView,
        redirect: '/basic-data/user',
        meta: { title: '基础数据', icon: 'basic-data'},
        children: [
          {
            path: '/basic-data/user',
            name: 'basic-data-user',
            component: RouteView,
            meta: { title: '用户数据' },
            redirect: '/basic-data/user/list',
            children: [
              {
                path: '/basic-data/user/list',
                name: 'basic-data-user-list',
                component: () => import('@/views/basic-data/user/list'),
                meta: {title: '用户数据', crumbsTitleHidden: true}
              },
              {
                path: '/basic-data/user/login-log',
                name: 'basic-data-user-login-log',
                component: () => import('@/views/basic-data/user/login-log'),
                meta: {title: '登录日志'}
              }
            ]
          },
          {
            path: '/basic-data/company',
            name: 'basic-data-company',
            component: () => import('@/components/Develop'),
            meta: { title: '企业数据' },
          },
          {
            path: '/basic-data/goods',
            name: 'basic-data-goods',
            component: () => import('@/components/Develop'),
            meta: { title: '商品数据' },
          },
          {
            path: '/basic-data/production',
            name: 'basic-data-production',
            component: () => import('@/components/Develop'),
            meta: { title: '生产数据' },
          },
          {
            path: '/basic-data/stock',
            name: 'basic-data-stock',
            component: () => import('@/components/Develop'),
            meta: { title: '库存数据' },
          },
          {
            path: '/basic-data/deal',
            name: 'basic-data-deal',
            component: () => import('@/components/Develop'),
            meta: { title: '交易数据' },
          },
          {
            path: '/basic-data/logistics',
            name: 'basic-data-logistics',
            component: () => import('@/components/Develop'),
            meta: { title: '物流数据' },
          },
          {
            path: '/basic-data/market',
            name: 'basic-data-market',
            component: () => import('@/components/Develop'),
            meta: { title: '市场数据' },
          },
          {
            path: '/basic-data/finance',
            name: 'basic-data-finance',
            component: () => import('@/components/Develop'),
            meta: { title: '金融数据' },
          },
          {
            path: '/basic-data/policy',
            name: 'basic-data-policy',
            component: () => import('@/components/Develop'),
            meta: { title: '政策数据' },
          },
          {
            path: '/basic-data/other',
            name: 'basic-data-other',
            component: () => import('@/components/Develop'),
            meta: { title: '其它产业数据' },
          },
        ]
      },
      {
        path: '/data-model',
        name: 'data-model',
        component: RouteView,
        redirect: '/data-model/index',
        meta: { title: '数据模型', icon: 'data-model'},
        children: [
          {
            path: '/data-model/index',
            name: 'data-model-index',
            component: () => import('@/components/Develop'),
            meta: { title: '二级标题' },
          }
        ]
      },
      {
        path: '/data-analysis',
        name: 'data-analysis',
        component: RouteView,
        redirect: '/data-analysis/index',
        meta: { title: '数据分析', icon: 'data-analysis'},
        children: [
          {
            path: '/data-analysis/index',
            name: 'data-analysis-index',
            component:() => import('@/components/Develop'),
            meta: { title: '二级标题' },
          }
        ]
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: BasicLayout,
    redirect: '/user/profile',
    children: [
      {
        path: '/user/profile',
        name: 'user-profile',
        component: () => import('@/views/user/profile'),
        meta: {title: '个人中心', showHomeLink: true}
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'hash', // history
  // base: process.env.BASE_URL,
  routes
})

export default router
