import API_ENV from "./api.env"

let API_PREFIX = {}

if (API_ENV === 'local') {
    API_PREFIX = {
        base_url: 'https://api.dev.ysintelligent.com/open_platform', // 'http://10.10.1.74:9020',  // 'https://api.dev.ysintelligent.com/open_platform'
        user_base_url: 'https://api.dev.ysintelligent.com/user_service', // http://10.10.1.74:9999
        login_base_url: 'https://api.dev.ysintelligent.com/yijiajia_admin/p',
        img_url_prefix: 'https://static.dev.ysintelligent.com',
    }
}

if (API_ENV === 'dev') {
    API_PREFIX = {
        base_url: 'https://api.dev.ysintelligent.com/open_platform',
        user_base_url: 'https://api.dev.ysintelligent.com/user_service',
        login_base_url: 'https://api.dev.ysintelligent.com/yijiajia_admin/p',
        img_url_prefix: 'https://static.dev.ysintelligent.com',
    }
}

if (API_ENV === 'test') {
    API_PREFIX = {
        base_url: 'https://api.test.ysintelligent.com/open_platform',
        user_base_url: 'https://api.test.ysintelligent.com/user_service',
        login_base_url: 'https://api.test.ysintelligent.com/yijiajia_admin/p',
        img_url_prefix: 'https://static.test.ysintelligent.com',
    }
}

if (API_ENV === 'prod') {
    API_PREFIX = {
        base_url: 'https://api.ysintelligent.com/open_platform',
        user_base_url: 'https://api.ysintelligent.com/user_service',
        login_base_url: 'https://api.ysintelligent.com/yijiajia_admin/p',
        img_url_prefix: 'https://static.ysintelligent.com',
    }
}

export default API_PREFIX