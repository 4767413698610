import { cookiesList, setCookie, getCookie, clearLoginData } from "@/utils/cookies"
import { getAdminInfo } from '@/api/user'
import router from '../../router'
const user = {
    state: {
        adminAvatar: '',
        loginInfo: {
            adminCellphone: '',
            adminCompanyName: '',
            adminId: '',
            adminPosition: '',
            adminRealname: '',
            menu: '',
            userLoginToken: '',
            yijiajiaUserToken: '',
        }
    },
    mutations: {
        setLoginInfo: (state, data) => {
            state.loginInfo = {
                ...state.loginInfo,
                ...data
            }
        },
        setAdminAvatar: (state, data) => {
            state.adminAvatar = data
        },
        resetUserState: (state) => {
            let obj = {}
            for (let key in state.loginInfo) {
                obj[key] = ''
            }
            state.loginInfo = {
                ...obj
            }
        }
    },
    actions: {
        setLoginInfo({commit}, data) {
            commit('setLoginInfo', data)
            if (data.rememberMe) {
                setCookie(cookiesList.token, data.loginInfo.userLoginToken, 15) // 保存15天
            } else {
                setCookie(cookiesList.token, data.loginInfo.userLoginToken) // Session
            }
            localStorage.setItem('login-info', JSON.stringify(data.loginInfo))
        },
        getLoginInfo({dispatch, commit}) {
            let token = getCookie(cookiesList.token)
            // console.log(token)
            if (token) {
                let localLoginInfo = localStorage.getItem('login-info')
                try {
                    localLoginInfo = JSON.parse(localLoginInfo)
                    commit('setLoginInfo', localLoginInfo)
                    dispatch('getAdminInfo')
                } catch (error) {
                    console.warn(error)
                }
            }
        },
        getAdminInfo({state,commit}) {
            return new Promise((resolve, reject) => {
                getAdminInfo({adminId: state.loginInfo.adminId}).then(res => {
                    console.log(res)
                    if (res.code === 200) {
                        resolve(res)
                        let updateData = {}
                        let {adminPosition, adminRealname, adminCompanyName, adminCellphone, adminAvatar} = res.data
                        if (adminPosition !== state.loginInfo.adminPosition) {
                            updateData.adminPosition = res.data.adminPosition
                        }
                        if (adminRealname !== state.loginInfo.adminRealname) {
                            updateData.adminRealname = res.data.adminRealname
                        }
                        if (adminCompanyName !== state.loginInfo.adminCompanyName) {
                            updateData.adminCompanyName = res.data.adminCompanyName
                        }
                        if (adminCellphone !== state.loginInfo.adminCellphone) {
                            updateData.adminCellphone = res.data.adminCellphone
                        }
                        if (Object.keys(updateData).length > 0) {
                            commit('setLoginInfo', updateData)
                            localStorage.setItem('login-info', JSON.stringify(state.loginInfo))
                        }
                        if (adminAvatar) commit('setAdminAvatar', adminAvatar)
                    } else {
                        reject(res)
                    }
                    
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        clearLocalData({commit}) {
            commit('resetUserState')
            clearLoginData()
        },
        logOut({dispatch}) { 
            dispatch('clearLocalData')
            router.push('/login')
        }
    }
}
export default user