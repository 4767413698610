import router from "./router"
import store from './store'

const whiteRoutes = ['/', '/login', '/404']

router.beforeEach((to,from, next) => {
    // console.log(to, from)
    if (!store.getters.userLoginToken) {
        store.dispatch('getLoginInfo')
    }
    if (store.getters.userLoginToken) {
        // console.log('store.getters.userLoginToken')
        next()
    } else {
        if (whiteRoutes.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    } 
})

