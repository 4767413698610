const app = {
    state: {
        spinning: {
            loading: false,
            tip: ''
        }
    },
    mutations: {
        setSpinning(state, data) {
            state.spinning = data
        }
    }
}

export default app