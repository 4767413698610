import Vue from 'vue'
import store from '@/store'

/* 全局loading */
function showLoading(options) {
    options = {
        loading: true,
        tip: options && options.tip || ''
    }
    store.commit('setSpinning', options)
}
function hideLoading() {
    store.commit('setSpinning', {
        loading: false,
        tip: ''
    })
}

Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading
