/*
* 按需加载 Icons
*
* 请注意，如果出现有组件图标不显示，
* 请将该组件使用到的图标在这里引入
**/

/* Layout begin */
export {
    default as Left,
} from '@ant-design/icons/lib/outline/LeftOutline'
export {
    default as Right,
} from '@ant-design/icons/lib/outline/RightOutline'
export {
    default as Down,
} from '@ant-design/icons/lib/outline/DownOutline'
export {
    default as Up,
} from '@ant-design/icons/lib/outline/UpOutline'
export {
    default as Close,
} from '@ant-design/icons/lib/outline/CloseOutline'
export {
    default as Loading,
} from '@ant-design/icons/lib/outline/LoadingOutline'

export {
    default as Calendar
} from '@ant-design/icons/lib/outline/CalendarOutline'
export {
    default as Check
} from '@ant-design/icons/lib/outline/CheckOutline'

/* Feedback begin */
export {
    default as QuestionCircleOutline,
} from '@ant-design/icons/lib/outline/QuestionCircleOutline'

export {
    default as CheckCircleOutline,
} from '@ant-design/icons/lib/outline/CheckCircleOutline'

export {
    default as ExclamationCircleOutline,
} from '@ant-design/icons/lib/outline/ExclamationCircleOutline'

export {
    default as InfoCircleOutline,
} from '@ant-design/icons/lib/outline/InfoCircleOutline'

export {
    default as CloseCircleOutline,
} from '@ant-design/icons/lib/outline/CloseCircleOutline'

/* Feedback end */

export {
    default as CloseCircle,
} from '@ant-design/icons/lib/fill/CloseCircleFill'
export {
    default as CheckCircle,
} from '@ant-design/icons/lib/fill/CheckCircleFill'
export {
    default as ExclamationCircle,
} from '@ant-design/icons/lib/fill/ExclamationCircleFill'
export {
    default as InfoCircle,
} from '@ant-design/icons/lib/fill/InfoCircleFill'
