<template>
    <div class="mid-pla-basic-layout">
        <a-spin :spinning="loading" :tip="tip" wrapperClassName="mp-layout-spinning-wrapper">
            <GlobalHeader />
            <SiderMenu />
            <router-view />
        </a-spin>
    </div>
</template>

<script>
import SiderMenu from './components/SiderMenu'
import GlobalHeader from './components/GlobalHeader'
import {mapState} from 'vuex'
export default {
    components: {
        SiderMenu,
        GlobalHeader
    },
    computed: {
        ...mapState({
            loading: state => state.app.spinning.loading,
            tip: state => state.app.spinning.tip
        })
    }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>