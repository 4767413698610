<template>
    <div class="mid-pla-main-content">
        <CrumbsNav>
            <slot name="crumbsExtra"></slot>
        </CrumbsNav>
        <slot></slot>
    </div>
</template>

<script>
import CrumbsNav from '../CrumbsNav'
export default {
    name: 'page-head-wrapper',
    components: {
        CrumbsNav
    }
}
</script>