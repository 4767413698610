<template>
    <div class="mid-pla-global-header">
        <div class="system-title">
            <img class="logo" src="../../../assets/imgs/logo.png" title="产业数据中台" alt="产业数据中台">
        </div>
    </div>
</template>

<script>
export default {

}
</script>